import { getWrapperOverlay, HoverWrapper } from "@/builderComponents"
import CatalogueSelection from "@/components/catalogue"
import Card from "@/components/Template/components/Card"
import Adaptive from "@/components/Template/components/ManageCatalogue/Adaptive"
import Desktop from "@/components/Template/components/ManageCatalogue/Desktop"
import { TransparentDivider } from "@/components/TransparentDivider"
import { Routes } from "@/enums"
import { useGetWebstoreConfigByOwner, useUserDetails } from "@/hooks"
import { useScreenSize } from "@/hooks/useScreenSize"
import {
	ActionType,
	builderMenuReducerAtom,
	builderModeEnabledAtom,
	canFilterTypeAtom,
	cardsCacheAtom,
	EditItem,
	selectedViewDetailAtom,
	sortedCardsAtom,
} from "@/state"
import { CardProps, Products, Services } from "@/types"
import { mapProductsToCard } from "@/utils"
import { mapServicesToCard } from "@/utils/service"
import { theme } from "@ikhokha/commons-ui/build/dist/cjs/theme"
import { useMediaQuery } from "@mui/material"
import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import Stack from "@mui/material/Stack"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import kebabcase from "lodash.kebabcase"
import { useRouter, useSearchParams } from "next/navigation"
import React from "react"

type Props = {
	webstoreName: string
	products: Products
	services: Services
	isPublicFacing?: boolean
	isConfigMode?: boolean
}

const Component = (props: Omit<Props, "isPublicFacing">) => {
	const router = useRouter()
	const { lg: lgScreenSize } = useScreenSize()

	const setSelectedViewDetail = useSetAtom(selectedViewDetailAtom)

	const products = mapProductsToCard(props.products)
	const services = mapServicesToCard(props.services)
	const cardsCache = [...products, ...services]

	const setCardsCache = useSetAtom(cardsCacheAtom)
	const [canFilterType, setCanFilterType] = useAtom(canFilterTypeAtom)

	const sortedCards = useAtomValue(sortedCardsAtom)

	React.useEffect(() => {
		setCardsCache(cardsCache)
		setCanFilterType(!!(props.products.length && props.services.length))
	}, [props.products, props.services])

	const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))
	const isConfig = props.isConfigMode ?? false

	const handleViewDetail = (card: CardProps) => {
		if (!props.webstoreName) {
			return
		}

		setSelectedViewDetail({ ...card, quantity: 1, webstoreName: props.webstoreName })

		const name = kebabcase(card.name)
		const route = name ? `${name}-${card.id}` : card.id

		router.push(`${props.webstoreName}/${route}`)
	}

	const cards = React.useMemo(
		() =>
			sortedCards.map((card) => (
				<Grid key={card.id} xs={1}>
					<Stack width="100%" alignItems="center">
						<Card card={card} elevateOnHover={isDesktop} handleClick={() => handleViewDetail(card)} isConfig={isConfig}>
							<Card.Image />
							<TransparentDivider height="1rem" />

							<Card.Name />
							<Card.Price />
							<TransparentDivider height=".5rem" />

							{canFilterType ? <Card.CaptionWithType /> : <Card.Caption />}
						</Card>
					</Stack>
				</Grid>
			)),
		[sortedCards],
	)

	return (
		<Box py="1.5rem" sx={{ background: "white" }} data-scroll-to={EditItem.CatalogueItems}>
			{lgScreenSize ? (
				<Desktop cards={cards} productsCount={products.length} servicesCount={services.length} isConfigMode={isConfig} />
			) : (
				<Adaptive cards={cards} />
			)}
		</Box>
	)
}

const Builder = (props: Props) => {
	const tab = useSearchParams()?.get("tab")
	const router = useRouter()

	const builderMode = useAtomValue(builderModeEnabledAtom)

	const [menuState, dispatch] = useAtom(builderMenuReducerAtom)

	const active = menuState.activeEditingItem === EditItem.CatalogueItems

	const handleEditClick = (element: HTMLElement | null) => {
		if (!element) return
		dispatch({ type: ActionType.EditItem, payload: { editItemType: EditItem.CatalogueItems, element } })
	}

	const { owner } = useUserDetails()
	const { data: webstoreConfigData } = useGetWebstoreConfigByOwner(owner ?? "")

	const handleClose = () => {
		dispatch({ type: ActionType.EditItem, payload: { editItemType: EditItem.None } })
		if (tab && webstoreConfigData?.webstoreName) {
			router.push(`/${webstoreConfigData.webstoreName}${Routes.Manage_Builder}`)
		}
	}

	const isConfigMode = builderMode && !props.isPublicFacing

	if (builderMode) {
		return (
			<HoverWrapper labelText="Products" active={active} overlay={getWrapperOverlay(active, handleEditClick, "Manage items")}>
				<Component {...props} isConfigMode={isConfigMode} />
				<Dialog open={active} maxWidth={false}>
					<Stack justifyContent="center" alignItems="center" height="42rem" width="52rem" px="2rem" py="1.25rem">
						{!!owner && !!webstoreConfigData?.webstoreConfigDraft && !!webstoreConfigData?.webstoreName && (
							<CatalogueSelection
								sitebuilder
								owner={owner}
								webstoreConfig={webstoreConfigData.webstoreConfigDraft}
								webstoreName={webstoreConfigData.webstoreName}
								onSave={handleClose}
							/>
						)}
					</Stack>
				</Dialog>
			</HoverWrapper>
		)
	}

	return <Component {...props} />
}

export default Builder
