import contextFactory from "@/provider/ContextFactory"
import { CardProps } from "@/types"

export type Context = {
	card: CardProps
	cardSize: string
	hovered: boolean
	isConfig?: boolean
}

const defaultContext: Context = {
	card: {
		id: "",
		name: "",
		description: "",
		category: "",
		caption: "",
		price: 0,
		variants: [],
		imageUrls: [],
		createdAt: "",
		type: "product",
		isVatApplicable: false,
	},
	cardSize: "0",
	hovered: false,
	isConfig: false,
}

export const { ProviderWrapper: CardProvider, useContext: useCardContext } = contextFactory<Context>(defaultContext)
