import { catalogueSortByAtom, openSortDrawerAtom } from "@/state"
import { sortByOptions } from "@/utils/manageCatalogue"
import { palette } from "@ikhokha/commons-ui/build/dist/cjs/palette"
import { alert } from "@ikhokha/commons-ui/build/dist/cjs/typography"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import Stack from "@mui/material/Stack"
import { alpha } from "@mui/material/styles"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import Typography from "@mui/material/Typography"
import { X } from "@phosphor-icons/react"
import { useAtom } from "jotai"
import * as React from "react"

export default function Sort() {
	const [selectedSortBy, setSelectedSortBy] = useAtom(catalogueSortByAtom)

	const [openSortDrawer, setOpenSortDrawer] = useAtom(openSortDrawerAtom)

	const handleClose = () => {
		setOpenSortDrawer(false)
	}

	return (
		<SwipeableDrawer anchor="bottom" open={openSortDrawer} onOpen={() => {}} onClose={handleClose}>
			<Stack>
				<Stack direction="row" justifyContent="space-between" p="1rem" pb="0">
					<Typography sx={alert.title}>Sort by</Typography>
					<IconButton aria-label="close" onClick={handleClose}>
						<X />
					</IconButton>
				</Stack>
				<List
					sx={{
						pb: "2rem",
						mx: "1rem",
					}}
				>
					{sortByOptions.map((option) => (
						<ListItemButton
							key={option.value}
							selected={option.value === selectedSortBy.value}
							onClick={() => {
								setSelectedSortBy(option)
								handleClose()
							}}
							sx={{
								"&.Mui-selected": {
									backgroundColor: alpha(palette.common.black, 0.08),
								},
								"&.Mui-selected:hover": {
									backgroundColor: alpha(palette.common.black, 0.08),
								},
								py: ".2rem",
							}}
						>
							<ListItemText primary={option.label} />
						</ListItemButton>
					))}
				</List>
			</Stack>
		</SwipeableDrawer>
	)
}
