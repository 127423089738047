import { Controls } from "@/components/Template/components/ManageCatalogue/Adaptive/Controls"
import { useScreenSize } from "@/hooks/useScreenSize"
import { defaultPageSize } from "@/utils"
import { Button } from "@mui/material"
import Stack from "@mui/material/Stack"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import * as React from "react"

const initialPageSize = 12

type Props = {
	cards: React.ReactNode[]
}
export default function Adaptive(props: Readonly<Props>) {
	const { lg: lgScreenSize } = useScreenSize()
	const [pageSize, setPageSize] = React.useState(initialPageSize)

	return (
		<Stack paddingX={`${lgScreenSize ? 5 : 1}rem`}>
			<Controls />
			<Grid container direction="row" columns={2} rowGap={3}>
				{props.cards.slice(0, pageSize)}
			</Grid>
			{props.cards.length > pageSize && (
				<Stack alignItems="center" my="1rem">
					<Button
						onClick={() => setPageSize(pageSize + defaultPageSize)}
						variant="outlined"
						sx={{
							color: "text.primary",
							borderColor: "text.primary",
							textTransform: "none",
							borderRadius: ".625rem",
							":hover": {
								borderColor: "text.primary",
								backgroundColor: "white",
							},
						}}
					>
						Load more
					</Button>
				</Stack>
			)}
		</Stack>
	)
}
