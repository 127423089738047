import { CardProvider, useCardContext } from "@/components/Card/CardProvider"
import CatalogueItemThumbnail from "@/components/CatalogueItemThumbnail"
import { useScreenSize } from "@/hooks/useScreenSize"
import { CardProps } from "@/types"
import { getCloudfrontImageUrl, getRandCurrencyText } from "@/utils"
import { alert } from "@ikhokha/commons-ui/build/dist/cjs/typography"
import { ListItemAvatar } from "@mui/material"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import React from "react"

type Props = React.PropsWithChildren & {
	card: CardProps
	elevateOnHover?: boolean
	handleClick: () => void
	isConfig?: boolean
}

/**
 * React compound component for rendering a catalogue item card. Provided
 * components for Image, Name, Price, Caption, CaptionWithType. Uses React Context
 * for data sharing between nested components
 *
 * @see https://www.patterns.dev/react/compound-pattern/
 */
function Card({ children, card, elevateOnHover, handleClick, isConfig = false }: Readonly<Props>) {
	const { cardSize } = useScreenSize()

	/**
	 * Hover effect used only on desktop
	 */
	const [hovered, setHovered] = React.useState(false)
	/**
	 * The number of columns is based on if the webstore has an even
	 * or odd amount of items, the card size will be adjusted fit accordingly
	 */

	return (
		<CardProvider context={{ card, cardSize, hovered, isConfig }}>
			<Paper
				elevation={elevateOnHover && hovered ? 3 : 0}
				onMouseOver={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
				sx={{
					maxWidth: cardSize,
					padding: "1rem",
					cursor: "pointer",
				}}
			>
				<Stack onClick={handleClick} alignItems="center">
					<Box>{children}</Box>
				</Stack>
			</Paper>
		</CardProvider>
	)
}

Card.Image = function Image() {
	const {
		card: { name, imageUrls },
		cardSize,
	} = useCardContext()

	return (
		<ListItemAvatar
			sx={{
				".MuiAvatar-root": {
					height: cardSize,
					width: cardSize,
				},
			}}
		>
			<CatalogueItemThumbnail imageUrl={getCloudfrontImageUrl(imageUrls[0])} name={name} />
		</ListItemAvatar>
	)
}

Card.Name = function Name() {
	const {
		card: { name },
		hovered,
	} = useCardContext()

	return (
		<Typography variant="body2" sx={{ textDecoration: hovered ? "underline" : "unset" }}>
			{name}
		</Typography>
	)
}

Card.Price = function Price() {
	const {
		card: { price, variants },
		hovered,
	} = useCardContext()

	return (
		<Typography sx={{ textDecoration: hovered ? "underline" : "unset", ...alert.title }}>
			{variants.length === 0
				? getRandCurrencyText(price)
				: `from ${getRandCurrencyText(Math.min(...variants.map((variant) => variant.price)))}`}
		</Typography>
	)
}

/**
 * This component is rendered if the webstore has either only products or only services
 * See {@link CaptionWithType} In the scenario that the webstore has both products and services
 */
Card.Caption = function Caption() {
	const { caption } = useCardContext().card

	return <Typography variant="caption">{caption}</Typography>
}

/**
 * This component is rendered if the webstore has both products and services selected
 * and displays the item type as context.
 * See {@link Caption} In the scenario that the webstore has either only products or only services
 */
Card.CaptionWithType = function CaptionWithType() {
	const { type, caption } = useCardContext().card

	return (
		<Stack direction="row" gap=".25rem">
			<Typography color="text.secondary" variant="caption" textTransform="capitalize">
				{type}
			</Typography>
			{caption && (
				<Typography color="text.secondary" variant="caption">
					•
				</Typography>
			)}
			<Typography variant="caption">{caption}</Typography>
		</Stack>
	)
}

export default Card
