import { catalogueSortByAtom } from "@/state"
import { sortByOptions } from "@/utils/manageCatalogue"
import { palette } from "@ikhokha/commons-ui/build/dist/cjs/palette"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Stack from "@mui/material/Stack"
import { alpha } from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import { CaretDown, CaretUp } from "@phosphor-icons/react"
import { useAtom } from "jotai"
import React from "react"

export function Sort() {
	const [sortBy, setSortBy] = useAtom(catalogueSortByAtom)

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = !!anchorEl
	const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)

	const handleMenuItemClick = (option: any) => {
		setSortBy(option)
		setAnchorEl(null)
	}

	const handleClose = () => setAnchorEl(null)

	return (
		<Stack direction="row" alignItems="center">
			<Typography variant="body1">Sort by:</Typography>
			<List component="nav" aria-label="sort by" sx={{ bgcolor: "background.paper" }}>
				<ListItemButton
					id="sort-by-button"
					aria-haspopup="listbox"
					aria-controls="sort-by-menu"
					aria-label="sort by"
					aria-expanded={open ? "true" : undefined}
					sx={{ borderRadius: ".5rem" }}
					onClick={handleClickListItem}
				>
					<Typography variant="body1">{sortBy.label}</Typography>
					{open ? <CaretUp /> : <CaretDown />}
				</ListItemButton>
			</List>
			<Menu
				id="sort-by-menu"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "sort-by-button",
					role: "listbox",
				}}
			>
				{sortByOptions.map((option) => (
					<MenuItem
						sx={{
							"&.Mui-selected": {
								backgroundColor: palette.common.white,
							},
							"&.Mui-selected:hover": {
								backgroundColor: alpha(palette.common.black, 0.08),
							},
						}}
						key={option.value}
						selected={option.value === sortBy.value}
						onClick={() => handleMenuItemClick(option)}
					>
						{option.label}
					</MenuItem>
				))}
			</Menu>
		</Stack>
	)
}
